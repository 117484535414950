import React, {useEffect} from "react";
import {deleteCheckout} from "store/actions/cart.action";
import Layout from "components/layout.component";
import {Link} from "gatsby";
import {TranslationKeys} from "../../interfaces/translation-keys.enum";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../hooks/use-redux.hook";


const PaymentCancelled = (props: {}): JSX.Element => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(deleteCheckout())
    })

    return (
        <Layout>
            <div
                className="w-full sm:rounded-md bg-white overflow-hidden sm:shadow py-2 flex justify-center content-center text-center">
                <div className="block">
                    <svg
                        className="w-20 h-20 text-red-600 inline-flex justify-center"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <h1 className="text-xl mb-4">{t(TranslationKeys.CHECKOUT_CANCELLED)}</h1>
                    {typeof window !== "undefined" &&
                    localStorage.getItem("current_url") ? (
                        <Link
                            to={localStorage.getItem("current_url") || "/"}
                            className="btn btn-primary inline-block w-full"
                        >
                            {t(TranslationKeys.COMMON_BACK_TO_EVENT)}
                        </Link>
                    ) : (
                        <Link
                            to="/cart"
                            className="btn btn-primary inline-block w-full"
                        >
                            {t(TranslationKeys.COMMON_PROCEED_TO_CART)}
                        </Link>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default PaymentCancelled;
